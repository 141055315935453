<template>
  <div class="spotHallPageBox">
    <!-- 开市时间 -->
    <div class="openingTime">
      <img class="annunciateImg" src="@/assets/icon/annunciate.png" alt="icon">
      <span>温馨提示：{{ `开市时间为${$store.state.tradeTimeRule.tradeDay === '00' ? '工作日&nbsp;&nbsp;' : '每天&nbsp;&nbsp;' }`+ `${$store.state.tradeTimeRule.state === 1 ? $store.state.tradeTimeRule.openTime + '-' + $store.state.tradeTimeRule.closeTime : '00:00-23:59'}` }}</span>
    </div>
    <!-- 选择搜索框 -->
    <Search v-show="$route.query.goodsType !== '0'" :goods-type="formInline.goodsTopCategoryId" @changeGoodsCondition="changeGoodsCondition" />
    <!-- 排序 -->
    <div class="sortPageBox">
      <span :class="sortActive === 1 ? 'active' : ''" @click="changeActive(1)">综合排序</span>
      <span :class="sortActive === 2 ? 'active' : ''" @click="changeActive(2)">商品单价
        <p class="icon">
          <i :class="sortPrice === 1 ? 'el-icon-caret-top active' : 'el-icon-caret-top'" />
          <i :class="sortPrice === 2 ? 'el-icon-caret-bottom active' : 'el-icon-caret-bottom'" />
        </p>
      </span>
      <span :class="sortActive === 3 ? 'active' : ''" @click="changeActive(3)">
        库存重量
        <p class="icon">
          <i :class="sortStock === 1 ? 'el-icon-caret-top active' : 'el-icon-caret-top'" />
          <i :class="sortStock === 2 ? 'el-icon-caret-bottom active' : 'el-icon-caret-bottom'" />
        </p>
      </span>
    </div>
    <!-- 产品列表 -->
    <div class="product-List-box">
      <div v-for="item in listData" :key="item.id" class="list">
        <!-- 图片 -->
        <img class="product-img" :src="(item.goodsMainImgUrl&&item.goodsMainImgUrl.indexOf('http')>=0) ? item.goodsMainImgUrl : $fileUrl + item.goodsMainImgUrl" alt="产品图片">
        <!-- 品名  公司名   仓库 -->
        <div class="product-item">
          <div class="goods-name elp-1">
            {{ item.goodsName }}
          </div>
          <div class="product-item-details">
            <div>
              <p class="goods-two-category-name">
                {{ item.goodsTwoCategoryName }}
              </p>
              <div class="flex-row">
                <p class="cmp-name beyond-hide-1" :title="item.cmpName">
                  <img class="icon" src="@/assets/icon/cmp.png" alt="">
                  {{ item.cmpName }}
                </p>
                <p class="cmp-concat">
                  <el-popover placement="top-start" title="挂牌联系人" width="100" trigger="hover" :content="item.listingPerson + ' ' + item.personPhone">
                    <span slot="reference" class="immediately-consult">
                      <img class="icon" src="@/assets/icon/concat.png" alt="">
                      联系卖家
                    </span>
                  </el-popover>
                </p>
              </div>
            </div>
            <div>
              <p class="warehouse-name elp-1">
                {{ item.warehouseName }} | {{ item.placeProvince + item.placeCity }}
              </p>
              <p class="goods-material elp-1">
                {{ item.goodsMaterialName + ' ' + item.goodsSpecName }}
              </p>
            </div>
            <div>
              <p class="price">
                ￥{{ item.unitPriceIncludingTax }}/吨
              </p>
              <p class="available">
                库存重量：{{ item.availableListingWeight }}吨
              </p>
            </div>
            <div class="button">
              <button @click="viewDetails(item)">
                查看详情
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination v-if="listData.length>0" :get-data-list="getTradeGoodsHallPage" :params="formInline" :total="total" />
    <NoData v-else class="noData" :type="3" />
  </div>
</template>

<script>
import Search from '@/components/Search.vue'
import Pagination from '@/components/Pagination.vue'
import NoData from '@/components/NoData.vue'
import { goodsHallPageSpotHallGoods } from '@/http/hangOut'
export default {
  components: { Pagination, Search, NoData },
  data() {
    return {
      sortActive: 1,
      sortPrice: 0,
      sortStock: 0,
      total: 0,
      formInline: {
        pageSize: 10,
        pageNum: 1,
        goodsTopCategoryId: '0',
        keyword: ''
      },
      listData: [],
      // 搜索条件列表
      searchConditionList: []
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        if (newVal.query.goodsType) {
          this.formInline.goodsTopCategoryId = newVal.query.goodsType === '0' ? '' : newVal.query.goodsType
        }
        this.formInline.keyword = newVal.query.keyword || ''
        if (newVal.query.keyword) this.getTradeGoodsHallPage()
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.$store.dispatch('getTradeTimeState')
    this.$store.dispatch('getTradeTime')
  },
  methods: {
    // 点击排序
    changeActive(index) {
      this.sortActive = index
      this.formInline.pageSize = 10
      this.formInline.pageNum = 1
      this.formInline.orderRuleData = {}
      switch (index) {
        case 2:
          this.sortStock = 0
          this.formInline.orderRuleData.orderColumnName = 'unitPriceIncludingTax'
          if (this.sortPrice === 2) {
            this.sortPrice = 0
            return
          }
          this.sortPrice += 1
          if (this.sortPrice === 1) this.formInline.orderRuleData.orderRule = 'asc'
          if (this.sortPrice === 2) this.formInline.orderRuleData.orderRule = 'desc'
          break
        case 3:
          this.sortPrice = 0
          this.formInline.orderRuleData.orderColumnName = 'availableListingWeight'
          if (this.sortStock === 2) {
            this.sortStock = 0
            return
          }
          this.sortStock += 1
          if (this.sortStock === 1) this.formInline.orderRuleData.orderRule = 'asc'
          if (this.sortStock === 2) this.formInline.orderRuleData.orderRule = 'desc'
          break
        default:
          this.sortPrice = 0
          this.sortStock = 0
          this.formInline.pageSize = 10
          this.formInline.pageNum = 1
          delete this.formInline.orderRuleData
          break
      }
      this.formInline.goodsTopCategoryId = this.$route.query.goodsType === '0' ? '' : this.$route.query.goodsType
      this.getTradeGoodsHallPage()
    },
    // 现货大厅列表
    getTradeGoodsHallPage() {
      goodsHallPageSpotHallGoods(this.formInline, res => {
        this.listData = res.data.records
        this.total = res.data.total
      })
    },
    // 搜索条件查询
    changeGoodsCondition(value) {
      this.$nextTick(() => {
        this.formInline.goodsCategoryName = this.formInline.goodsMaterialName = this.formInline.goodsSpecName = ''
        if (!value || value.length === 0) return this.getTradeGoodsHallPage()
        this.formInline.goodsCategoryName = value[0].goodsName
        const material = value.find((item) => item.type === 'material')
        if (material) this.formInline.goodsMaterialName = material.goodsName
        const spec = value.find((item) => item.type === 'spec')
        if (spec) this.formInline.goodsSpecName = spec.goodsName
        this.getTradeGoodsHallPage()
      })
    },
    viewDetails({ id, shopId }) {
      const routeUrl = this.$router.resolve({
        path: '/spotHall/spotHallDetail',
        query: { id, shopId }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.spotHallPageBox {
  width: $centerPlateWidth;
  margin: 54px auto 0;
  .noData{
    padding: 35px 0 100px;
  }

  .product-List-box {
    background-color: #fff;

    .list {
      display: flex;
      height: 80px;
      padding: 16px 24px;
      overflow: hidden;
      font-size: 14px;
      &:hover {
        background: rgba(0,0,0,0.03);
      }

      .product-img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-right: 16px;
      }

      .product-item {
        display: flex;
        flex-direction: column;
        color: $mainFont;
        flex: 1;

        .goods-name {
          width: 340px;
          font-size: 16px;
          margin-bottom: 4px;
        }

        .product-item-details {
          display: flex;
          justify-content: space-between;
          > div {
            &:first-child {
              width: 340px;
            }
            &:nth-child(2) {
              width: 200px;
            }
            &:nth-child(3) {
              width: 150px;
            }
          }
          .goods-two-category-name {
            color: $themeColors;
            background: rgba(211,30,14,0.08);
            display: inline-block;
            padding: 2px 8px;
            margin-bottom: 8px;
          }
          .icon {
            width: 16px;
            height: 16px;
            vertical-align: middle;
            margin-top: -2px;
            margin-right: 4px;
          }
          .cmp-name {
            width: 240px;
            color: $fontColor5;
            margin-right: 8px;
          }
          .cmp-concat {
            color: #F89705;
            cursor: pointer;
          }

          .warehouse-name {
            font-size: 16px;
            margin-bottom: 8px;
          }
          .goods-material {
            color: $fontColor5;
          }

          .price {
            color: $themeColors;
            font-size: 22px;
            vertical-align: top;
            margin-bottom: 4px;
          }
          .available {
            color: #999;
          }

          .button button{
            font-size: 16px;
            border: 1px solid #E7E7E7;
            color: $themeColors;
            height: 38px;
            width: 102px;
            background-color: #fff;
            font-size: 12px;
            transition: 0.4s;
            &:hover {
              background-color: $themeColors;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .sortPageBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 42px;
    background-color: rgba(0,0,0,0.03);
    padding-left: 18px;

    span {
      width: auto;
      height: 40px;
      margin-right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: $mainFont;
      cursor: pointer;

      .icon {
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        i {
          font-size: 10px;
          color: #C1C1C1;
          transform: translateY(-2px);
        }

        i:first-of-type {
          transform: translateY(2px);
        }

        .active {
          color: #333;
        }
      }
    }

    span.active {
      border-bottom: 1px solid $themeColors;
      color: $themeColors;
    }
  }

  .openingTime {
    margin: 18px 0;
    background: rgba(243,131,27,0.1);
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #F3831B;

    .annunciateImg {
      height: 20px;
      width: 20px;
      margin: 0 12px 0 21px;

    }
  }
}
</style>
